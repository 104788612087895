<template>
  <div class="box">
    <div class="hello">
      <h1>{{ typedText }}</h1>
      <br>
      <p class="teamname">
        爱玩  爱写  爱折腾
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      typedText: '', // 初始化为空字符串
      fullText: '一个由学生组成的计算机爱好者团队', // 完整的文本内容
      index: 0, // 当前索引位置
      typingSpeed: 100, // 打字速度，单位毫秒
    };
  },
  mounted() {
    this.typeWriter(); // 页面加载完成后开始打字动画
  },
  methods: {
    typeWriter() {
      if (this.index < this.fullText.length) {
        this.typedText += this.fullText.charAt(this.index); // 每次增加一个字符
        this.index++;
        setTimeout(this.typeWriter, this.typingSpeed); // 递归调用自身，实现打字动画效果
      }
    },
  },
};
</script>

<style scoped>
.hello {
  margin-top: 300px;
  color: #000000;
}
.teamname {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

<template>
  <div class="app-container">
    <nav class="navbar">
      <div class="nav-left">
        <img class="logo" src="./assets/link.png" alt="Logo">
        <a class="team-name">青风团队</a>
      </div>
      <!-- 菜单 -->
      <div class="mobile-menu">
        <button type="button" class="menu-button" @click="showMenu = !showMenu">
          <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4278" width="16" height="16"><path d="M128 128h768v128H128zM128 768h768v128H128zM128 448h768v128H128z" fill="#231815" p-id="4279"></path></svg>
        </button>
        <ul class="mobile-menu-list" :class="{ show: showMenu }">
          <li class="mobile-menu-item" @click="showMenu = false">
            <router-link to="/">主页</router-link>
          </li>
          <li class="mobile-menu-item" @click="showMenu = false">
            <router-link to="/about">关于</router-link>
          </li>
          <li class="mobile-menu-item" @click="showMenu = false">
            <router-link to="/join">加入</router-link>
          </li>
        </ul>
      </div>
      <div class="nav-links">
        <router-link to="/" class="a_font">主页</router-link>&nbsp;&nbsp;
        <router-link to="/about" class="a_font">关于</router-link>&nbsp;&nbsp;
        <router-link to="/join" class="a_font">加入</router-link>
      </div>
    </nav>
    <article>
      <router-view/>
    </article>
    <div class="footer">
        总有人间一两风，填我十万八千梦 |
      <a href="https://www.breezecode.cn" class="link" target="_blank"> 官网</a> |
      <a href="https://code.breezecode.tech" class="link" target="_blank"> 代码</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      showMenu: false
    }
  }
}
</script>

<style lang="scss" scoped>
  @use './style.scss';
</style>

<template>
    <div class="home">
      <HelloWorld msg="一个由学生组成的计算机爱好者团队"/>
    </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue';

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
  },
  data() {
    return {
      typedText: '', // 初始化为空字符串
      fullText: '青风团队', // 完整的文本内容
      index: 0, // 当前索引位置
      typingSpeed: 100, // 打字速度，单位毫秒
    };
  },
  mounted() {
    this.typeWriter(); // 页面加载完成后开始打字动画
  },
  methods: {
    typeWriter() {
      if (this.index < this.fullText.length) {
        this.typedText += this.fullText.charAt(this.index); // 每次增加一个字符
        this.index++;
        setTimeout(this.typeWriter, this.typingSpeed); // 递归调用自身，实现打字动画效果
      }
    },
  },
};
</script>

<style scoped>
.home {
  text-align: center;
  margin-top: 0px;
  color: #333; /* 文字颜色 */
}

@keyframes fadeIn {
  from {
    opacity: 0; /* 从完全透明开始 */
  }
  to {
    opacity: 1; /* 渐变到完全不透明 */
  }
}

.logo {
  margin-top: 50px;
}

img {
  border: none; /* 移除图片边框 */
}
</style>
